<template>
  <v-dialog v-model="state" :persistent="true" width="536">
    <v-card class="modal">
      <v-card-title class="modal-title">Selecionar turno</v-card-title>

      <v-divider></v-divider>

      <validation-observer>
        <v-form @submit.prevent="submit" slot-scope="{ invalid }">
          <div class="data-container py-6 px-5">
            <field
              v-model="shift"
              type="select"
              :items="Object.keys(shifts)"
              label="Turno"
              name="turno"
              :isRequired="true"
            />
          </div>

          <v-divider></v-divider>

          <v-card-actions class="d-flex flex-wrap justify-space-between">
            <v-btn
              class="mt-1 black-3--text cancel-button"
              color="#FFF"
              @click="backBtn"
              :block="$isMobile"
              >Voltar</v-btn
            >
            <v-btn
              type="submit"
              :disabled="invalid"
              color="secondary-color-1"
              class="ma-0 mt-1 white--text next-step-button"
              :block="$isMobile"
              >Selecionar turno</v-btn
            >
          </v-card-actions>
        </v-form>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>
<script>
  import { ValidationObserver } from "vee-validate";

  import Field from "@/modules/core/views/components/Field";
  import { shiftTypes } from "@/modules/agenda/enums/shiftTypes.enum";

  export default {
    name: "SelectShiftDialog",

    props: {
      backBtn: {
        type: Function,
        required: true
      },
      state: {
        type: Boolean,
        required: true
      },
      shiftProp: {
        type: null
      }
    },

    components: {
      ValidationObserver,
      Field
    },

    data: () => ({
      shift: ""
    }),

    computed: {
      shifts() {
        return shiftTypes;
      }
    },

    mounted() {
      if (this.shiftProp) {
        this.shift = this.shiftProp;
      }
    },

    watch: {
      shiftProp() {
        if (this.shiftProp) {
          this.shift = this.shiftProp;
        }
      }
    },

    methods: {
      submit() {
        this.$emit("submit", this.shift);
      }
    }
  };
</script>
<style lang="scss" scoped>
  @import "@/assets/stylesheets/styleguide";

  .data-container {
    overflow-y: auto;
    overflow-x: hidden;
  }
</style>
