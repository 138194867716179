<template>
  <v-dialog v-model="state" :persistent="true" width="536">
    <v-card class="modal">
      <v-card-title class="modal-title">Alterar data</v-card-title>

      <v-card-text>
        <v-date-picker
          v-model="pickerDate"
          header-color="primary-color-2"
          color="primary-color-2"
          locale="pt-br"
          full-width
        ></v-date-picker>
      </v-card-text>

      <validation-observer>
        <v-form @submit.prevent="submit" slot-scope="{ invalid }">
          <v-card-actions class="d-flex flex-wrap justify-space-between">
            <v-btn
              class="mt-1 black-3--text cancel-button"
              color="#FFF"
              :block="$isMobile"
              @click="backBtn"
              >Voltar</v-btn
            >
            <v-btn
              type="submit"
              :disabled="invalid"
              color="secondary-color-1"
              class="ma-0 mt-1 white--text next-step-button"
              :block="$isMobile"
              >Confirmar</v-btn
            >
          </v-card-actions>
        </v-form>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>
<script>
  import { mapActions } from "vuex";

  import { ValidationObserver } from "vee-validate";

  export default {
    name: "EditScheduleDateDialog",

    props: {
      value: {
        type: null
      },
      backBtn: {
        type: Function,
        required: true
      },
      state: {
        type: Boolean,
        required: true
      }
    },

    components: {
      ValidationObserver
    },

    mounted() {
      if (this.value) {
        this.pickerDate = this.value;
      }
    },

    watch: {
      value() {
        if (this.value) {
          this.pickerDate = this.value;
        }
      }
    },

    data: () => ({
      pickerDate: "",
      selectedDate: {
        showContent: false,
        dateSelected: "",
        dateSelectedLabel: ""
      }
    }),
    methods: {
      ...mapActions(["toggleSnackbar"]),

      async submit() {
        this.selectedDate.dialog = false;
        this.$emit("submit", this.pickerDate);
      }
    }
  };
</script>
<style lang="scss" scoped>
  @import "@/assets/stylesheets/styleguide";

  .data-container {
    height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
  }
</style>
