<template>
  <div>
    <v-menu transition="slide-x-transition" bottom right>
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-bind="attrs" v-on="on">mdi-dots-horizontal</v-icon>
      </template>
      <v-list>
        <v-list-item
          @click="requestProfile"
          class="d-flex align-center item-dropdown"
        >
          <v-list-item-title>
            <v-icon class="item-dropdown-icon">mdi-account</v-icon>
            <span class="pl-3">Ver informações</span>
          </v-list-item-title>
        </v-list-item>

        <v-list-item
          v-if="
            canCancelRequest && situationState.canChangeSituation && !isHistoric
          "
          @click="situationState.dialog = true"
          class="d-flex align-center item-dropdown"
        >
          <v-list-item-title>
            <v-icon class="item-dropdown-icon">mdi-block-helper</v-icon>
            <span class="pl-2">{{ text }}</span>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <change-request-situation-dialog
      :scheduleId="scheduleId"
      :data="data"
      :state="situationState"
      @success="updateData"
    />
  </div>
</template>
<script>
  import ChangeRequestSituationDialog from "@/modules/agenda/views/components/ChangeRequestSituationDialog";

  export default {
    name: "RequestMenuItem",

    props: {
      data: {
        required: true,
        type: Object
      },
      canCancelRequest: {
        required: true,
        type: Boolean
      },
      text: {
        required: true,
        type: String
      },
      isHistoric: {
        type: Boolean
      },
      scheduleId: {
        type: Number
      }
    },
    components: {
      ChangeRequestSituationDialog
    },
    data: () => ({
      situationState: {
        dialog: false,
        canChangeSituation: false,
        mode: {
          icon: null,
          label: ""
        }
      }
    }),

    methods: {
      requestProfile() {
        return this.$router.push({
          name: this.isHistoric ? "ViewHistoryRequest" : "ViewRequest",
          params: { id: this.data.id }
        });
      },
      updateData() {
        this.$emit("updateData");
      }
    }
  };
</script>
<style lang="scss" scoped>
  @import "@/assets/stylesheets/styleguide";

  .item-dropdown:hover {
    background-color: $white-1;

    .item-dropdown-icon {
      color: $primary-color-2;
    }
  }
</style>
