<template>
  <v-dialog v-model="state.dialog" scrollable :persistent="true" width="488">
    <validation-observer>
      <v-form @submit.prevent="submit()" slot-scope="{ invalid }">
        <v-card class="modal">
          <v-card-title class="modal-title">{{
            situationModalTitle
          }}</v-card-title>
          <v-divider></v-divider>

          <v-card-text>
            <div class="pt-5">
              <h3>Tem certeza que deseja cancelar a solicitação?</h3>

              <div v-if="data.situationId === 1">
                <p class="mt-5">Descreva o motivo do cancelamento</p>
                <v-row>
                  <v-col cols="12">
                    <Field
                      v-model="form.reasonSituation"
                      type="textarea"
                      label="Motivo do cancelamento"
                      name="reasonSituation"
                      max="250"
                      :isRequired="true"
                    />
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions class="d-flex flex-wrap justify-space-between">
            <v-btn
              class="mt-1 black-3--text cancel-button"
              color="#ffffff"
              @click="state.dialog = false"
              :block="$isMobile"
              >Cancelar</v-btn
            >
            <v-btn
              type="button"
              @click="actionChangeSituation"
              color="error"
              :disabled="invalid"
              class="mt-1 ma-0 next-step-button"
              :block="$isMobile"
              >Confirmar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </validation-observer>
  </v-dialog>
</template>
<script>
  import { ValidationObserver } from "vee-validate";
  import { mapActions } from "vuex";

  import Field from "@/modules/core/views/components/Field";

  export default {
    name: "ChangeRequestSituationDialog",

    props: {
      data: {
        required: true,
        type: Object
      },
      state: {
        required: true,
        type: Object
      },
      scheduleId: {
        type: Number
      }
    },
    components: {
      ValidationObserver,
      Field
    },
    data: () => ({
      dialogChangeRequestSituation: false,
      situationModalTitle: "",
      form: {
        reasonSituation: ""
      }
    }),
    mounted() {
      this.initialize();
    },
    watch: {
      "request.situation": function() {
        this.initialize();
      }
    },
    methods: {
      ...mapActions("agenda", [
        "removeCollectionRequestFromSchedule",
        "cancelCollectionRequest"
      ]),
      ...mapActions(["toggleSnackbar"]),
      initialize() {
        this.state.canChangeSituation = true;
        this.situationModalTitle = `Cancelar agendamento`;
      },
      async actionChangeSituation() {
        try {
          let response;

          if (this.data.situationId === 1) {
            response = await this.cancelCollectionRequest({
              requestId: this.data.id,
              reasonSituation: this.form.reasonSituation
            });
            this.$router.go();
          } else {
            response = await this.removeCollectionRequestFromSchedule({
              requestId: this.data.id,
              scheduleId: this.scheduleId
            });
            this.$router.go();

            return this.$router.push({
              name: "agenda"
            });
          }

          this.$emit("success");
          this.toggleSnackbar({
            text: response?.message ?? "Solicitação cancelada com sucesso",
            type: "success"
          });
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        } finally {
          this.state.dialog = false;
        }
      }
    }
  };
</script>
<style></style>
