<template>
  <v-container>
    <title-header :title="`${$route.meta.title} ${this.$route.params.id}`" />
    <v-card>
      <v-row class="d-flex justify-space-between">
        <v-col cols="12" md="4" align="center">
          <h3 class="title-card">
            Motorista:
            <v-chip v-if="schedule.driver" pill @click="selectDriver()">
              <user-photo
                class="mr-2"
                v-model="schedule.driver.photoHash"
                size="sm"
                border="thin"
              />
              {{ `${schedule.driver.firstName} ${schedule.driver.surname}` }}
              <v-icon size="16" right>mdi-pencil</v-icon>
            </v-chip>
          </h3>
        </v-col>
        <v-col cols="12" md="4" align="center">
          <h3 class="title-card">
            Data do agendamento:
            <em v-if="schedule.scheduleDate" class="schedule-detail">
              {{
                schedule.scheduleDate
                  .split("-")
                  .reverse()
                  .join("/")
              }}
              <v-icon size="16" right @click="editScheduleDate"
                >mdi-pencil</v-icon
              >
            </em>
          </h3>
        </v-col>
        <v-col cols="12" md="4" align="center">
          <h3 class="title-card">
            Turno:
            <em class="schedule-detail">{{ schedule.shiftValue }}</em>
            <v-icon size="16" right @click="editShift">mdi-pencil</v-icon>
          </h3>
        </v-col>
      </v-row>
    </v-card>
    <v-card class="mt-5">
      <v-row align-content="center" class="pa-3">
        <v-col class="d-flex justify-space-between align-center">
          <h3>Solicitações</h3>
        </v-col>
        <v-col cols="3" sm="4" md="2">
          <report-options
            fileName="solicitações do agendamento"
            :header="headerReport"
            :body="collectionRequestReport"
          />
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-data-table
        :headers="headers"
        :items-per-page="collectionRequest.length"
        :items="collectionRequest"
        hide-default-footer
      >
        <template v-slot:[`item.situationId`]="{ item }">
          <request-menu-item
            :data="item"
            :scheduleId="schedule.id"
            @updateData="updateData"
            :canCancelRequest="canCancelRequest"
            text="Cancelar solicitação"
          />
        </template>
      </v-data-table>
    </v-card>
    <select-driver-dialog
      :backBtn="() => (showSelectDriverDialog = false)"
      :state="showSelectDriverDialog"
      :driverSelectedProp="schedule.driver ? schedule.driver.id : null"
      @submit="selectDriver"
    />
    <select-shift-dialog
      :backBtn="() => (showSelectShiftDialog = false)"
      :state="showSelectShiftDialog"
      :shiftProp="schedule.shift"
      @submit="selectShift"
    />
    <edit-schedule-date-dialog
      :backBtn="() => (showSelectDateDialog = false)"
      :state="showSelectDateDialog"
      :value="schedule.scheduleDate"
      @submit="selectDate"
    />
  </v-container>
</template>
<script>
  import { mapGetters, mapActions } from "vuex";

  import { profiles } from "@/modules/core/enums/profiles.enum";

  import TitleHeader from "@/modules/core/views/components/TitleHeader";
  import TableHandler from "@/modules/core/mixins/tableHandler";
  import SelectDriverDialog from "@/modules/agenda/views/components/SelectDriverDialog";
  import ReportOptions from "@/modules/core/views/components/ReportOptions";
  import SelectShiftDialog from "@/modules/agenda/views/components/SelectShiftDialog";
  import EditScheduleDateDialog from "@/modules/agenda/views/components/EditScheduleDateDialog";
  import MasksMixin from "@/modules/core/mixins/masksMixin";
  import RequestMenuItem from "@/modules/agenda/views/components/RequestMenuItem";
  import UserPhoto from "@/modules/account/user/views/components/UserPhoto";

  export default {
    name: "ViewSchedule",

    mixins: [MasksMixin, TableHandler],

    components: {
      SelectDriverDialog,
      ReportOptions,
      RequestMenuItem,
      SelectShiftDialog,
      EditScheduleDateDialog,
      UserPhoto,
      TitleHeader
    },
    data: () => ({
      form: {},
      currentPage: 1,
      showSelectDriverDialog: false,
      showSelectShiftDialog: false,
      selectedDriver: "",
      showSelectDateDialog: false,
      schedule: [],
      collectionRequest: [],
      collectionRequestReport: [],
      shifts: {
        Matutino: "Matutino",
        Vespertino: "Vespertino",
        Noturno: "Noturno"
      },
      headers: [
        {
          text: "Lote",
          align: "start",
          value: "batch"
        },
        { text: "Data/Hora", value: "createdAt" },
        { text: "Tipo", value: "type.description" },
        { text: "Doador/Parceiro", value: "sourceName" },
        { text: "Telefone", value: "phone" },
        { text: "Cidade", value: "address.city" },
        { text: "Status", value: "situation.description" },
        { value: "situationId" }
      ],
      headerReport: [
        {
          header: "ID",
          dataKey: "id"
        },
        { header: "Data/Hora", dataKey: "createdAt" },
        { header: "Tipo", dataKey: "description" },
        { header: "Doador/Parceiro", dataKey: "sourceName" },
        { header: "Telefone", dataKey: "phone" },
        { header: "Cidade", dataKey: "address" },
        { header: "Status", dataKey: "situation" }
      ]
    }),

    async mounted() {
      this.initPermissions();
      await this.getScheduleDetailMethod();
      this.collectionRequestReport = [...this.collectionRequest].map(
        request => {
          return {
            ...request,
            address: request.address.city,
            description: request.type.description,
            situation: request.situation.description
          };
        }
      );
    },

    computed: {
      ...mapGetters({
        user: "user/getUser",
        userIsAdmin: "user/getUserIsAdmin",
        userIsManagerOperator: "user/getUserIsManagerOperator"
      }),
      canManageUser() {
        return this.userIsAdmin || this.userIsManagerOperator;
      }
    },
    methods: {
      ...mapActions(["toggleSnackbar"]),
      ...mapActions("agenda", ["getScheduleDetail", "updateScheduleById"]),

      initPermissions() {
        const usersCanCancelRequest = [
          profiles.MANAGER_OPERATING.id,
          profiles.RECEPTIONIST.id
        ];

        this.canCancelRequest = usersCanCancelRequest.includes(
          this.user.role.id
        );
      },

      async getScheduleDetailMethod() {
        try {
          const { data } = await this.getScheduleDetail(this.$route.params.id);

          this.schedule = {
            ...data,
            shiftValue: data.shift,
            driver: {
              ...data.driver,
              photoHash: data?.driver?.photo?.hash ?? null
            }
          };

          this.collectionRequest = this.formatCollectionRequestData(
            data.collectionRequests
          );
        } catch (err) {
          this.toggleSnackbar({
            text: err?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      },

      formatCollectionRequestData(collection) {
        return collection.map(data => {
          const phone = data.phones.length
            ? this.parsePhone(data.phones[0])
            : "não informado";

          return {
            ...data,
            createdAt: this.parseDate(data.createdAt, "dd/MM/yyyy - HH:mm"),
            batch: data.batch.batchNumber,
            phone
          };
        });
      },

      editScheduleDate() {
        this.showSelectDateDialog = true;
      },

      editShift() {
        this.showSelectShiftDialog = true;
      },

      updateData() {},

      async selectDriver(driver) {
        if (driver) {
          this.showSelectDriverDialog = false;
          this.selectedDriver = driver;
          this.form.driverId = driver.id;
          await this.submitForm();
        } else {
          this.form = {};
          this.showSelectDriverDialog = true;
          this.selectedDriver = "";
        }
      },

      async selectDate(date) {
        if (date) {
          this.showSelectDateDialog = false;
          this.form.scheduleDate = date;
          await this.submitForm();
          this.form = {};
        }
      },

      async selectShift(shift) {
        if (shift) {
          this.showSelectShiftDialog = false;
          this.form.shift = shift;
          await this.submitForm();
          this.form = {};
        }
      },

      async submitForm() {
        try {
          const response = await this.updateScheduleById({
            id: this.$route.params.id,
            payload: this.form
          });

          this.toggleSnackbar({
            text: response?.data?.message ?? "Agendamento alterado com sucesso",
            type: "success"
          });
        } catch (error) {
          this.toggleSnackbar({
            text: error.data?.message ?? "Ocorreu um erro inesperado",
            type: "error"
          });
        } finally {
          await this.getScheduleDetailMethod();
        }
      },

      labelSeletectedDriver() {
        if (this.selectedDriver) {
          return `${this.selectedDriver.firstName} ${this.selectedDriver.surname}`;
        }
        return "";
      },

      goToAddSchedule() {
        this.$router.push({ name: "newRequest" });
      }
    }
  };
</script>
<style lang="scss" scoped>
  @import "@/assets/stylesheets/styleguide";
  @import "@/assets/stylesheets/components";

  @include search-bar();

  .title {
    margin-bottom: 20px;
    left: 0;
    transition: left 0.4s !important;
    -webkit-transition: left 0.4s !important;
    .page-title {
      font-size: 1.5rem;
      color: #ffffff;

      .icon-back {
        color: #ffffff;
        cursor: pointer;
      }
    }
  }

  .title-card {
    color: $black-1;
    opacity: 1;
    font-size: 16px;
  }

  .schedule-detail {
    color: $primary-color-2;
    font-style: normal;
    font-size: 14px;
  }
</style>
